<template lang="pug">
  .card
    .card-body(style="overflow: hidden;")
      .card-title {{header}}
        .float-sm-right
          | {{$t("widgets.touriBook.book1")}}
          | &nbsp;
          .badge.badge-sm.badge-pill.badge-success {{bookingTicketsCount}}
          | &nbsp;
          | {{$tc("widgets.touriBook.book2", bookingTicketsCount)}}
      .d-table.w-100(v-if="bookingTicketsCount > 0")
        .float-left
          a.button(href="#bookingTicketCarousel" role="button" data-slide="prev")
            i(style="color:#c2c2c2")
              font-awesome-icon(:icon="['fas', 'chevron-left']")
        .float-right
          a.button(href="#bookingTicketCarousel" role="button" data-slide="next" @click="loadMoreBookingTickets")
            i(style="color:#c2c2c2")
              font-awesome-icon(:icon="['fas', 'chevron-right']")
      #bookingTicketCarousel.carousel.slide(data-ride='carousel' data-interval="false")
        .carousel-inner
          template(v-for="bookingTicket in bookingTickets")
            .carousel-item(:class="{ 'active': bookingTickets.indexOf(bookingTicket) == 0 }" :id="bookingTicket.Id")
              touribook-request-panel(
                :booking-ticket-id="bookingTicket.Id"
                :display-detail-button="true"
              )

</template>

<script>
import { BookingTicketStatusEnum } from "../../../lib/enums/bookingTicketStatus/BookingTicketStatusEnum";
import { BookingTickets } from "@/lib/graphql/bookingTickets/BookingTickets.ts";

export default {
    name: "BookingTicketsWidget",
    props: {
        header: String,
    },
    data() {
        return {
            hostIds: this.$session.get("hostIds"),
            filterValue: "",
            ticketStatus: [
                BookingTicketStatusEnum.DatesRequested,
                BookingTicketStatusEnum.DatesCancelRequested,
            ],
            offset: 0,
            limit: 2,

            bookingTickets: [],
            bookingTicketsCount: 0,
        };
    },
    async mounted() {
        try {
            this.loadBookingTickets();
            this.loadBookingTicketsCount();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadMoreBookingTickets() {
            try {
                this.limit = 1;
                this.offset += 1;
                await this.loadBookingTickets();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadBookingTickets() {
            try {
                const bookingTickets = await this.$apollo
                    .query({
                        query: BookingTickets.Queries.BookingTickets,
                        variables: {
                            hostIds: this.hostIds,
                            filterValue: this.filterValue,
                            ticketStatus: this.ticketStatus,
                            offset: this.offset,
                            limit: this.limit,
                        },
                    })
                    .then(({ data }) => data?.bookingTickets)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!bookingTickets) {
                    return;
                }
                this.bookingTickets =
                    this.bookingTickets.concat(bookingTickets);
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadBookingTicketsCount() {
            try {
                const bookingTicketsCount = await this.$apollo
                    .query({
                        query: BookingTickets.Queries.BookingTicketsCount,
                        fetchPolicy: "no-cache",
                        variables: {
                            hostIds: this.hostIds,
                            filterValue: this.filterValue,
                            ticketStatus: this.ticketStatus,
                        },
                    })
                    .then(({ data }) => data?.bookingTicketsCount)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (bookingTicketsCount == undefined) {
                    return;
                }
                this.bookingTicketsCount = bookingTicketsCount;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped></style>

<template lang="pug">
  .wrapper
    tD-loading-overlay(v-if="$apollo.loading")
    .row
      .col-12
        h2 {{$t("touriBook.Headline1")}}

    tableFilter(:isTouribook="true" :searchHeadline="'touriBook.Headline2'" :searchPlaceholder="'touriBook.searchInputPlaceholder'")

    .row.mt-3
      .col-12
        h5 {{$t("touriBook.Headline3")}}
      .col-sm-12.col-md-4
        pagesize(:isTouribook="true")
      .col-sm-12.col-md-4
        .button.button-primary.button-tdays.filter-touriBook.w-100(type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample") {{$t("touriBook.statusFilter")}}
        .collapse(id="collapseExample")
          .card.card-body
            ul.list-group
              li.list-group-item.border-0(v-for="bookingTicketStatus in BookingTicketStatus" v-bind:key="bookingTicketStatus.Id",)
                .form-check
                  input.form-check-input(type="checkbox",
                    :id="bookingTicketStatus.Id"
                    :value="parseFloat(bookingTicketStatus.Id)"
                    v-model="selectedBookingTicketStatus"
                    :checked="selectedBookingTicketStatus.includes(bookingTicketStatus.Id)"
                  )
                  label.form-check-label(:for="bookingTicketStatus.Id") {{$t("touriBook.bookingTicketStatus." + bookingTicketStatus.Description)}}

      .col-sm-12.col-md-4
        button.button.button-primary.button-tdays.w-100(@click="filterOpenBookingTickets") {{ $t('touriBook.showOpenRequests') }}

    .row.mt-3
      .col-12
        .tb-panel-wrapper(v-if="bookingTickets && Array.isArray(bookingTickets) && bookingTickets.length")
          touribook-request-panel(
            v-for="bookingTicket in bookingTickets"
            :key="bookingTicket.Id"
            :booking-ticket-id="bookingTicket.Id"
            :display-detail-button="true",
          )
      .col-12.mt-2
        new-pagination(:load-more="loadMoreBookingTickets" :overall-count="bookingTicketsCount" :current-count="bookingTickets.length")

</template>

<script>
import { GetBookingTicketStatusQuery } from "../../../lib/graphql/queries/bookingTicketStatus/GetBookingTicketStatusQuery";
import EventBus from "../../../views/event-bus";
import BookingTicketsComponent from "@/lib/components/bookingTickets/BookingTicketsComponent";
import NewPagination from "@/views/partials/new-pagination.vue";
import { BookingTicketStatusEnum } from "@/lib/enums/bookingTicketStatus/BookingTicketStatusEnum";

export default {
    name: "TouribookList",
    components: { NewPagination },
    props: {
        slug: String,
    },
    data() {
        return {
            bookingTickets: [],
            bookingTicketsCount: 0,
            BookingTicketStatus: [],
            hostId: this.$session.get("hostId"),

            limit: 10,
            offset: 0,
            privateFilterValue: "",
            filterValue: "",

            selectedBookingTicketStatus: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        };
    },
    mounted() {
        try {
            EventBus.$on(
                "changeHostId",
                function (hostId) {
                    this.hostId = hostId;
                }.bind(this)
            );
            this.loadBookingTickets();
            this.loadBookingTicketsCount();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        loadMoreBookingTickets() {
            try {
                this.offset += this.limit;
                this.loadBookingTickets();
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        async loadBookingTickets() {
            try {
                const bookingTicketsComponent = new BookingTicketsComponent();
                EventBus.$emit("changeLoadingState", true);
                const bookingTickets =
                    await bookingTicketsComponent.getBookingTickets(
                        this.$apollo,
                        [this.hostId],
                        this.selectedBookingTicketStatus,
                        this.filterValue,
                        this.offset,
                        this.limit
                    );
                EventBus.$emit("changeLoadingState", false);
                if (!bookingTickets) {
                    return;
                }
                this.bookingTickets =
                    this.bookingTickets.concat(bookingTickets);
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async loadBookingTicketsCount() {
            try {
                const bookingTicketsComponent = new BookingTicketsComponent();
                const bookingTicketsCount =
                    await bookingTicketsComponent.getBookingTicketsCount(
                        this.$apollo,
                        [this.hostId],
                        this.selectedBookingTicketStatus,
                        this.filterValue
                    );
                if (bookingTicketsCount == undefined) {
                    return;
                }
                this.bookingTicketsCount = bookingTicketsCount;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        async filterOpenBookingTickets() {
            try {
                this.offset = 0;
                this.bookingTickets = [];
                this.selectedBookingTicketStatus = [
                    BookingTicketStatusEnum.DatesRequested,
                    BookingTicketStatusEnum.DatesCancelRequested,
                ];
                this.loadBookingTickets();
                this.loadBookingTicketsCount();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
    apollo: {
        BookingTicketStatus: {
            query: GetBookingTicketStatusQuery,
            loadingKey: "loading",
        },
    },
};
</script>

<style scoped></style>

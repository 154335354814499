<template lang="pug">
  tr
    td {{ product.Id }}
    td {{ $n(product.Wert, "currency") }}
    td {{ $n(product.StartPreisFestPreis, "currency") }}
    td {{ $n(product.DealPreisLiveShopping, "currency") }}
    td.text-left {{ product.Arrangement }}
    td
      span(v-if="stock && (stock.Available - stock.Reserved > 0)") {{ stock.Available - stock.Reserved }}
      span(v-else) 0
    td
      span(v-if="dealStock && (dealStock.Available - dealStock.Reserved > 0)") {{ dealStock.Available - dealStock.Reserved }}
      span(v-else) 0
    td
      router-link.button.button-primary.button-tdays(:to="{ name: 'ProductDetails', params: { productId: product.Id } }" tag="button")
        | {{$t("constants.buttons.show")}}

</template>

<script>
import { Stock } from "@/lib/graphql/stock/Stock.ts";

export default {
    name: "product-table-column",
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            stock: undefined,
            dealStock: undefined,
        };
    },
    async mounted() {
        try {
            this.stock = await this.getStock();
            this.dealStock = await this.getStock(true);
        } catch (e) {
            console.log(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async getStock(isDealStock) {
            try {
                const stock = await this.$apollo
                    .query({
                        query: Stock.Queries.Stock,
                        fetchPolicy: "no-cache",
                        variables: {
                            productId: this.product.Id,
                            isDealStock: isDealStock,
                        },
                    })
                    .then(({ data }) => data?.stock)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!stock) {
                    return;
                }
                return stock;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

<style scoped></style>

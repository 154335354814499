import gql from "graphql-tag";

export const BookingTickets = {
    Queries: {
        BookingTickets: gql`
        query bookingTickets(
            $hostIds: [ID]
            $ticketStatus: [Int]
            $offset: Int
            $limit: Int
            $filterValue: String
        ) {
            bookingTickets(
                hostIds: $hostIds
                filterValue: $filterValue
                ticketStatus: $ticketStatus
                offset: $offset
                limit: $limit
            ) {
                Id
                Guid
                BookingRequestId
                BookingTicketStatusId
                CreatedAt
                UpdatedAt
                TimeLimitForProcessingTicketDays
                TimeLimitForBookingDateDays
                IsBindingBooking
                InternalComment
                MessageForCustomer
                TicketWatchedFirstTimeByHost
                StatusRequestByCustomer
                EmailRequestSentToHost
                EmailRequestReminderMailSentToHost
                EmailRequestSecondReminderMailSentToHost
                StatusRequestAutomaticallyDeniedBySystem
                EmailRequestAutomaticallyDeniedSentToHost
                EmailRequestAutomaticallyDeniedSentToCustomer
                StatusRequestDeniedByHost
                EmailRequestDeniedSentToCustomer
                StatusRequestAcceptedByHost
                EmailRequestAcceptedSentToCustomer
                EmailRequestAcceptedReminderSentToCustomer
                EmailRequestAcceptedSecondReminderSentToCustomer
                StatusBookedByCustomer
                EmailBookedSentToHost
                StatusAutomaticallyCancelledBySystem
                EmailAutomaticallyCancelledSentToCustomer
                EmailAutomaticallyCancelledSentToHost
                StatusHostMissedAllDates
                EmailHostMissedAllDatesSentToHost
                StatusQuotaCanBeSelected
                EmailQuotaCanBeSelectedSentToGuest
                Inactive
                EmailSentCustomerAltDatesAvailableByHost
                EmailSentCustomerAltDatesAvailableByHostReminder
                IsBookingCancelRequested
                FastAcceptedDatePerMail
                DatesRequestedAt
                DatesCanBeBookedAt
                DatesBookedAt
                DatesRejectedAt
                DatesCancelRequestedAt
                DatesCancelledAt
                HostDatesAcceptedCancelAt
                HostDatesDeclinedCancelAt
                CreatedBy
                UpdatedBy
            }
        }`,
        BookingTicketsCount: gql`
        query bookingTicketsCount(
            $hostIds: [ID]
            $ticketStatus: [Int]
            $filterValue: String
        ) {
            bookingTicketsCount(
                hostIds: $hostIds
                ticketStatus: $ticketStatus
                filterValue: $filterValue
            )
        }`,
        BookingTicket: gql`
        query bookingTicket($id: ID) {
            bookingTicket(id: $id) {
                Id
                Guid
                BookingRequestId
                BookingTicketStatusId
                CreatedAt
                UpdatedAt
                TimeLimitForProcessingTicketDays
                TimeLimitForBookingDateDays
                IsBindingBooking
                InternalComment
                MessageForCustomer
                TicketWatchedFirstTimeByHost
                StatusRequestByCustomer
                EmailRequestSentToHost
                EmailRequestReminderMailSentToHost
                EmailRequestSecondReminderMailSentToHost
                StatusRequestAutomaticallyDeniedBySystem
                EmailRequestAutomaticallyDeniedSentToHost
                EmailRequestAutomaticallyDeniedSentToCustomer
                StatusRequestDeniedByHost
                EmailRequestDeniedSentToCustomer
                StatusRequestAcceptedByHost
                EmailRequestAcceptedSentToCustomer
                EmailRequestAcceptedReminderSentToCustomer
                EmailRequestAcceptedSecondReminderSentToCustomer
                StatusBookedByCustomer
                EmailBookedSentToHost
                StatusAutomaticallyCancelledBySystem
                EmailAutomaticallyCancelledSentToCustomer
                EmailAutomaticallyCancelledSentToHost
                StatusHostMissedAllDates
                EmailHostMissedAllDatesSentToHost
                StatusQuotaCanBeSelected
                EmailQuotaCanBeSelectedSentToGuest
                Inactive
                EmailSentCustomerAltDatesAvailableByHost
                EmailSentCustomerAltDatesAvailableByHostReminder
                IsBookingCancelRequested
                FastAcceptedDatePerMail
                DatesRequestedAt
                DatesCanBeBookedAt
                DatesBookedAt
                DatesRejectedAt
                DatesCancelRequestedAt
                DatesCancelledAt
                HostDatesAcceptedCancelAt
                HostDatesDeclinedCancelAt
                CreatedBy
                UpdatedBy
            }
        }`,
        BookingTicketsByVoucherId: gql`
        query bookingTicketsByVoucherId($voucherId: ID) {
            bookingTicketsByVoucherId(voucherId: $voucherId) {
                Id
                Guid
                BookingRequestId
                BookingTicketStatusId
                CreatedAt
                UpdatedAt
                TimeLimitForProcessingTicketDays
                TimeLimitForBookingDateDays
                IsBindingBooking
                InternalComment
                MessageForCustomer
                TicketWatchedFirstTimeByHost
                StatusRequestByCustomer
                EmailRequestSentToHost
                EmailRequestReminderMailSentToHost
                EmailRequestSecondReminderMailSentToHost
                StatusRequestAutomaticallyDeniedBySystem
                EmailRequestAutomaticallyDeniedSentToHost
                EmailRequestAutomaticallyDeniedSentToCustomer
                StatusRequestDeniedByHost
                EmailRequestDeniedSentToCustomer
                StatusRequestAcceptedByHost
                EmailRequestAcceptedSentToCustomer
                EmailRequestAcceptedReminderSentToCustomer
                EmailRequestAcceptedSecondReminderSentToCustomer
                StatusBookedByCustomer
                EmailBookedSentToHost
                StatusAutomaticallyCancelledBySystem
                EmailAutomaticallyCancelledSentToCustomer
                EmailAutomaticallyCancelledSentToHost
                StatusHostMissedAllDates
                EmailHostMissedAllDatesSentToHost
                StatusQuotaCanBeSelected
                EmailQuotaCanBeSelectedSentToGuest
                Inactive
                EmailSentCustomerAltDatesAvailableByHost
                EmailSentCustomerAltDatesAvailableByHostReminder
                IsBookingCancelRequested
                FastAcceptedDatePerMail
                DatesRequestedAt
                DatesCanBeBookedAt
                DatesBookedAt
                DatesRejectedAt
                DatesCancelRequestedAt
                DatesCancelledAt
                HostDatesAcceptedCancelAt
                HostDatesDeclinedCancelAt
                CreatedBy
                UpdatedBy
            }
        }`,
        BookingTicketsByBookingRequestId: gql`
        query bookingTicketsByBookingRequestId($bookingRequestId: ID) {
            bookingTicketsByBookingRequestId(bookingRequestId: $bookingRequestId) {
                Id
                Guid
                BookingRequestId
                BookingTicketStatusId
                CreatedAt
                UpdatedAt
                TimeLimitForProcessingTicketDays
                TimeLimitForBookingDateDays
                IsBindingBooking
                InternalComment
                MessageForCustomer
                TicketWatchedFirstTimeByHost
                StatusRequestByCustomer
                EmailRequestSentToHost
                EmailRequestReminderMailSentToHost
                EmailRequestSecondReminderMailSentToHost
                StatusRequestAutomaticallyDeniedBySystem
                EmailRequestAutomaticallyDeniedSentToHost
                EmailRequestAutomaticallyDeniedSentToCustomer
                StatusRequestDeniedByHost
                EmailRequestDeniedSentToCustomer
                StatusRequestAcceptedByHost
                EmailRequestAcceptedSentToCustomer
                EmailRequestAcceptedReminderSentToCustomer
                EmailRequestAcceptedSecondReminderSentToCustomer
                StatusBookedByCustomer
                EmailBookedSentToHost
                StatusAutomaticallyCancelledBySystem
                EmailAutomaticallyCancelledSentToCustomer
                EmailAutomaticallyCancelledSentToHost
                StatusHostMissedAllDates
                EmailHostMissedAllDatesSentToHost
                StatusQuotaCanBeSelected
                EmailQuotaCanBeSelectedSentToGuest
                Inactive
                EmailSentCustomerAltDatesAvailableByHost
                EmailSentCustomerAltDatesAvailableByHostReminder
                IsBookingCancelRequested
                FastAcceptedDatePerMail
                DatesRequestedAt
                DatesCanBeBookedAt
                DatesBookedAt
                DatesRejectedAt
                DatesCancelRequestedAt
                DatesCancelledAt
                HostDatesAcceptedCancelAt
                HostDatesDeclinedCancelAt
                CreatedBy
                UpdatedBy
            }
        }`,
    },
    Mutations: {
        SaveBookingTicket: gql`
        mutation saveBookingTicket($bookingTicket: BookingTicketsInput) {
            saveBookingTicket(bookingTicket: $bookingTicket) {
                IsSuccess
                Message
                StringData
            }
        }`,
        CancelBookingTicket: gql`
        mutation cancelBookingTicket($id: ID, $userId: ID) {
            cancelBookingTicket(id: $id, userId: $userId) {
                IsSuccess
                Message
            }
        }`,
    },
};

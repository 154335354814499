<template lang="pug">
  .host-data-form
    .text-center.mb-4
      .h1 Schritt 1: Rechnungsdaten bearbeiten
      .txt-td-grey-basic
        | Hinterlegen Sie in diesem Schritt zunächst Ihre Adress- & Rechnungsdaten.
        br
        | Wenn alle Eingaben korrekt sind können Sie zum nächsten Schritt fortfahren.
    .row
      .col-12.col-lg-6
        .form-group
          label.form-label Firma*
          input.form-control(type="text" v-model="host.RAdresse1")
          .validation-message(:class="{'visible':!hasValidInvoiceCompany}") Bitte füllen Sie dieses Feld aus.
      .col-12.col-lg-6
        .form-group
          label.form-label Inhaber(in)*
          input.form-control(type="text" v-model="host.RAdresse3")
          .validation-message(:class="{'visible':!hasValidInvoiceOwner}") Bitte füllen Sie dieses Feld aus.
      .col-12.col-lg-6
        .form-group
          label.form-label Straße*
          input.form-control(type="text" v-model="host.RAdresse4")
          .validation-message(:class="{'visible':!hasValidInvoiceStreet}") Bitte füllen Sie dieses Feld aus.
      .col-12.col-lg-6
        .form-group
          label.form-label PLZ*
          input.form-control(type="text" v-model="host.RPLZ")
          .validation-message(:class="{'visible':!hasValidInvoicePLZ}") Bitte füllen Sie dieses Feld aus.
      .col-12.col-lg-6
        .form-group
          label.form-label Ort*
          input.form-control(type="text" v-model="host.RORT")
          .validation-message(:class="{'visible':!hasValidInvoiceLocation}") Bitte füllen Sie dieses Feld aus.
      .col-12.col-lg-6
        .form-group
          label.form-label Land*
          select.form-control(v-model="host.RLAND")
            option(v-for="addressCountry in addressCountries" :value="addressCountry.ISO31661alpha3") {{ addressCountry.CountryDE }} ({{ addressCountry.ISO31661alpha3 }})
      .col-12.col-lg-6
        .form-group
          label.form-label Website*
          input.form-control(type="text" v-model="host.Internetadresse2")
          .validation-message(:class="{'visible':!hasValidWebsite}") Bitte füllen Sie dieses Feld aus.
      .col-12.col-lg-6
        .form-group
          label.form-label UStID*
          input.form-control(type="text" v-model="host.UStIDNr" maxlength=50)
          .validation-message(:class="{'visible':!hasValidUstId}") Bitte füllen Sie dieses Feld aus.
      .col-12.col-lg-6
        .form-group
          label.form-label Kontoinhaber(in)*
          input.form-control(type="text" v-model="host.AccountOwner" maxlength=100)
          .validation-message(:class="{'visible':!hasValidAccountOwner}") Bitte füllen Sie dieses Feld aus.
      .col-12.col-lg-6
        .form-group
          label.form-label IBAN*
          input.form-control(type="text" v-model="host.IBAN" maxlength=50)
          .validation-message(:class="{'visible':!hasValidIBAN}") Bitte geben Sie eine gültige IBAN an.
      .col-12.col-lg-6
        .form-group
          label.form-label BIC*
          input.form-control(type="text" v-model="host.BIC" maxlength=50)
          .validation-message(:class="{'visible':!hasValidBIC}") Bitte geben Sie eine gültige BIC an.


</template>
<script>
import { IBAN, BIC } from "ibankit";
import { AddressCountries } from "@/lib/graphql/addressCountries/AddressCountries.ts";

export default {
    name: "HostDataStep",
    props: {
        host: {
            type: Object,
            required: true,
        },
        isActive: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            addressCountries: [],
        };
    },
    async mounted() {
        try {
            this.loadAddressCountries();
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadAddressCountries() {
            try {
                const addressCountries = await this.$apollo
                    .query({
                        query: AddressCountries.Queries.AddressCountries,
                    })
                    .then(({ data }) => data?.addressCountries)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!addressCountries) {
                    return;
                }
                this.addressCountries = addressCountries;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
    computed: {
        hasValidInvoiceCompany() {
            try {
                return this.host.RAdresse1?.trim().length;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
        hasValidInvoiceOwner() {
            try {
                return this.host.RAdresse3?.trim().length;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
        hasValidInvoiceStreet() {
            try {
                return this.host.RAdresse4?.trim().length;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
        hasValidInvoicePLZ() {
            try {
                return this.host.RPLZ?.trim().length;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
        hasValidInvoiceLocation() {
            try {
                return this.host.RORT?.trim().length;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
        hasValidInvoiceCountry() {
            try {
                return this.host.RLAND?.trim().length;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
        hasValidWebsite() {
            try {
                return this.host.Internetadresse2?.trim().length;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
        hasValidUstId() {
            try {
                return this.host.UStIDNr?.trim().length;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
        hasValidAccountOwner() {
            try {
                return this.host.AccountOwner?.trim().length;
            } catch (e) {
                console.error(e);
                return false;
            }
        },
        hasValidIBAN() {
            try {
                return IBAN.isValid(this.host.IBAN?.trim());
            } catch (e) {
                console.error(e);
                return false;
            }
        },
        hasValidBIC() {
            try {
                return BIC.isValid(this.host.BIC?.trim());
            } catch (e) {
                console.error(e);
                return false;
            }
        },
    },
};
</script>

import gql from "graphql-tag";

export const Stock = {
    Queries: {
        Stock: gql`
        query stock($productId: ID, $isDealStock: Boolean) {
            stock(productId: $productId, isDealStock: $isDealStock) {
                Base
                Available
                Reserved
                Paid
            }
        }`,
    }
}

<template lang="pug">
.wrapper
  tD-loading-overlay(v-if="$apollo.loading")
  .row
    .col-12
      h2 {{$t("products.detail.headline")}}
  .row(v-if="product")
    .col-12.col-md-4
      .form-group
        label(for="ProductNumber").form-label {{$t("products.list.table.productNo")}}.
        input#ProductNumber.form-control.custom-control(type="text", disabled="true", :value="product.Id")

    .col-12.col-md-4
      .form-group
        label(for="Status").form-label {{$t("products.detail.status")}}
        input#Status.form-control(type="text", disabled="true", :value="this.Description")
    .col-12.col-md-4
      .form-group
        label(for="VoucherType").form-label {{$t("products.list.table.price")}}
        input#VoucherType.form-control.custom-control(type="text", disabled="true", :value="product.ProdTyp")
  .row
    .col-12.col-md-4
      .form-group
        label(for="SalePrice").form-label {{$t("products.detail.price")}}
        input#SalePrice.form-control.custom-control(type="text", disabled="true", :value="product.Mindestverkaufspreis | formatEuro")
    .col-12.col-md-4
      .form-group
        label(for="DealPrice").form-label {{$t("products.detail.dealPrice")}}
        input#DealPrice.form-control.custom-control(type="text", disabled="true", :value="product.DealPreisMindestverkaufspreis | formatEuro")
    .col-12.col-md-4
      .form-group
        label(for="Value").form-label {{$t("products.detail.value")}}
        input#Value.form-control.custom-control(type="text", disabled="true", :value="product.Wert | formatEuro")
  .row
    .col-12.col-md-4
      .form-group
        label(for="Stock").form-label {{$t("products.detail.stock")}}
        input#Stock.form-control.custom-control(type="text", disabled="true" :value="stock ? stock.Available - stock.Reserved : ''")
    .col-12.col-md-4
      .form-group
        label(for="DealStock").form-label {{$t("products.detail.dealStock")}}
        input#DealStock.form-control.custom-control(type="text", disabled="true" :value="dealStock ? dealStock.Available - dealStock.Reserved : ''")
  .row
    .col-12
      .form-group
        label(for="Arrangement").form-label {{$t("products.detail.arrangement")}}
        input#Arrangement.form-control.custom-control(type="text", disabled="true", :value="product.Arrangement")

    .col-12
      .form-group
        label(for="HotelServices").form-label {{$t("products.detail.hotelServices")}}
        textarea#HotelServices.form-control(type="text", disabled="true", :rows="holidayServicesRows") {{holidayServices}}
    .col-12
      .form-group
        label(for="TouristicServices").form-label {{$t("products.detail.touristicalServices")}}
        textarea#TouristicServices.form-control(type="text", disabled="true", :rows="additionalHolidayServicesRows") {{additionalHolidayServices}}
    .col-12
      .form-group
        label(for="AdditionalInformation").form-label {{$t("products.detail.extraInfo")}}
        textarea#AdditionalInformation.form-control(type="text", disabled="true", :rows="additionalInfoRows") {{additionalInfo}}
    .col-12
      .form-group
        label(for="Bookability").form-label {{$t("products.detail.bookability")}}
        input#Bookability.form-control.custom-control(type="text", disabled="true", :value="product.Buchbarkeit")

  .row.mb-3
    .col-12
      .float-right
        router-link.button.button-primary.button-tdays(to="/products" tag="button")
          | {{$t("constants.buttons.back")}}
</template>

<script>
import { GetProductQuery } from "../../../lib/graphql/queries/products/GetProductQuery";
import { Stock } from "@/lib/graphql/stock/Stock.ts";

export default {
    name: "ProductDetails",
    props: {
        productId: String,
        slug: String,
    },
    data() {
        return {
            product: {},
            Description: "",
            holidayServices: "",
            holidayServicesRows: 1,
            additionalHolidayServices: "",
            additionalHolidayServicesRows: 1,
            additionalInfo: "",
            additionalInfoRows: 1,

            stock: undefined,
            dealStock: undefined,
        };
    },
    async mounted() {
        try {
            this.stock = await this.getStock();
            this.dealStock = await this.getStock(true);
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async getStock(isDealStock) {
            try {
                const stock = await this.$apollo
                    .query({
                        query: Stock.Queries.Stock,
                        fetchPolicy: "no-cache",
                        variables: {
                            productId: this.productId,
                            isDealStock: isDealStock,
                        },
                    })
                    .then(({ data }) => data?.stock)
                    .catch((e) => {
                        console.error(e);
                        return undefined;
                    });
                if (!stock) {
                    return;
                }
                return stock;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
    apollo: {
        product: {
            query: GetProductQuery,
            loadingKey: "loading",
            variables() {
                return {
                    id: this.$route.params.productId,
                };
            },
            result(response) {
                const product =
                    response && response.data && response.data.product
                        ? response.data.product
                        : undefined;
                if (!product) {
                    return;
                }
                this.Description = "";
                this.holidayServices = product.HolidayServices;
                this.holidayServicesRows = product.HolidayServices
                    ? product.HolidayServices.split("\n").length
                    : 0;
                this.additionalHolidayServices =
                    product.AdditionalHolidayServices;
                this.additionalHolidayServicesRows =
                    product.AdditionalHolidayServices
                        ? product.AdditionalHolidayServices.split("\n").length
                        : 1;
                this.additionalInfo = product.ZusatzInfo;
                this.additionalInfoRows = product.ZusatzInfo
                    ? product.ZusatzInfo.split("\n").length
                    : 1;
            },
        },
    },
};
</script>

<style scoped></style>

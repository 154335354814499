<template lang="pug">
  .row
    .col-12.home-wrapper
      .wrapper.h-100
        .row(v-if="permissions")
          .col-12.mb-3(v-if="permissions.find((p) => p.Id == PermissionsEnum.touriBook)")
            BookingTicketsWidget(:header="$t('dashboard.touriBookWidgetHeader')")
          .col-12.mb-3(v-if="permissions.find((p) => p.Id == PermissionsEnum.Vouchers)")
            VouchersWidget(:header="$t('dashboard.VoucherWidgetHeader')")
          template(v-if="permissions.find((p) => p.Id == PermissionsEnum.Sales)")
            .col-12.col-sm-6.mb-3
              SalesValueWidget(:header="$t('dashboard.SalesValueLast90Header')" :from="moment().subtract(90, 'days').toDate()")
            .col-12.col-sm-6.mb-3
              SalesFutureArrivalValueWidget(:header="$t('dashboard.SalesFutureArrivalHeader')")
            .col-12.col-sm-6.mb-3
              SalesCountWidget(:header="$t('dashboard.SalesCountHeader')" :from="moment().subtract(90, 'days').toDate()")
            .col-12.col-sm-6.mb-3
              SalesValueWidget(:header="$t('dashboard.SalesValueHeader')")
            .col-12.mb-3
              button.button.button-primary.button-tdays.w-100.mt-3(data-toggle='collapse' href='#collapseSalesWidget' role='button' aria-expanded='false' aria-controls='collapseSalesWidget') Umsatzgraph ausklappen
              #collapseSalesWidget.collapse
                SalesWidget(:header="$t('dashboard.SalesGraphLast90Header')")

</template>

<script>
import moment from "moment";
import VouchersWidget from "@/views/widgets/vouchers/VoucherWidget";
import BookingTicketsWidget from "@/views/widgets/booking-ticket/BookingTicketsWidget";
import SalesWidget from "@/views/widgets/sales/SalesWidget";
import SalesCountWidget from "@/views/widgets/sales/SalesCountWidget";
import SalesValueWidget from "@/views/widgets/sales/SalesValueWidget";
import SalesFutureArrivalValueWidget from "@/views/widgets/sales/SalesFutureArrivalValueWidget";
import { PermissionsEnum } from "@/lib/enums/permissions/PermissionsEnum";
import EventBus from "@/views/event-bus";

export default {
    name: "Home",
    components: {
        SalesFutureArrivalValueWidget,
        SalesValueWidget,
        SalesCountWidget,
        SalesWidget,
        BookingTicketsWidget,
        VouchersWidget,
    },
    data() {
        return {
            permissions: this.$session.get("permissions")
                ? this.$session.get("permissions")
                : [],

            PermissionsEnum: PermissionsEnum,
            moment: moment,
        };
    },
    async mounted() {
        try {
            EventBus.$on(
                "updateNav",
                function () {
                    this.permissions = this.$session.get("permissions");
                }.bind(this)
            );
        } catch (e) {
            console.error(e);
            this.$alert(e.message);
        }
    },
    methods: {
        addDays: function (date, days) {
            return moment(date).add(days, "days").toDate();
        },
    },
};
</script>

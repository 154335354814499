<template lang="pug">
  .card
    .card-body(style="overflow: hidden;")
      .card-title {{header}}
      .row
        .col-12.mb-3
          .input-group
            input.form-control.custom-control(type="text", :placeholder="this.$t('widgets.voucher.searchPlaceholder')", v-model="filter" v-on:keyup.enter="search")
            .input-group-append
              button.button.button-primary.button-tdays(@click="search") {{$t("widgets.voucher.searchButton")}}

        .col-12
          .table-responsive.scroll
            table.table.table-bordered.text-center.d-table(v-if="vouchers && vouchers.length > 0")
              thead
                tr
                  th(scope="col") {{$t("vouchers.list.table.voucherNo")}}
                  th(scope="col") {{$t("vouchers.list.table.paidOn")}}
                  th(scope="col") {{$t("vouchers.list.table.overnightStays")}}
                  th(scope="col") {{$t("vouchers.list.table.guest")}}
                  th(scope="col") {{$t("vouchers.list.table.voucherProceeds")}}
                  th(scope="col") {{$t("vouchers.list.table.status")}}
                  th(scope="col") {{$t("vouchers.list.table.arrivalOn")}}
                  th(scope="col") {{$t("vouchers.list.table.notes")}}
                  th(scope="col") {{$t("vouchers.list.table.details")}}
              tbody
                vouchers-table-row(
                  v-for="voucher in vouchers"
                  :voucher="voucher"
                  :key="voucher.Id"
                )

</template>

<script>
import VoucherStatusEnum from "@/lib/enums/voucherStatus/VoucherStatusEnum";
import EventBus from "@/views/event-bus";
import { VouchersComponent } from "@/lib/components/vouchers/VouchersComponent";
import VouchersTableRow from "@/views/pages/vouchers/partials/VouchersTableRow.vue";

export default {
    name: "VouchersWidget",
    components: { VouchersTableRow },
    props: {
        header: String,
    },
    data() {
        return {
            hostIds: this.$session.get("hostIds"),
            vouchers: [],
            filter: "",
        };
    },
    methods: {
        async search() {
            try {
                const vouchersComponent = new VouchersComponent();
                EventBus.$emit("changeLoadingState", true);
                const vouchers = await vouchersComponent.getHostsVouchers(
                    this.$apollo,
                    this.hostIds,
                    this.filter,
                    [
                        VoucherStatusEnum.Created,
                        VoucherStatusEnum.Sent,
                        VoucherStatusEnum.Booked,
                        VoucherStatusEnum.Redeemed,
                        VoucherStatusEnum.Cancelled,
                        VoucherStatusEnum.HostCancel,
                        VoucherStatusEnum.Refund,
                    ],
                    undefined,
                    undefined,
                    undefined,
                    25
                );
                EventBus.$emit("changeLoadingState", false);
                if (!vouchers) {
                    return;
                }
                this.vouchers = vouchers;
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
};
</script>

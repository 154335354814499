import { render, staticRenderFns } from "./settlements.vue?vue&type=template&id=6ef0c2f0&scoped=true&lang=pug"
import script from "./settlements.vue?vue&type=script&lang=js"
export * from "./settlements.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ef0c2f0",
  null
  
)

export default component.exports
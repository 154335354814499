<template lang="pug">
  .container-fluid
    .row(v-if="voucher")
      .col-12.mt-5.text-center
        label(style="font-size:18px") {{ $t("vouchers.detail.voucherNumber") }}
        br
        label(style="font-size:28px; font-weight:700" :class="{ 'text-danger': moment(voucher.ValidTill).isBefore(new Date()) }") {{ voucher.Id }}

      .col-12.col-md-6.mt-3
        .rounded-circle.align-self-center(:class="{ 'background-circle-active': voucher.LastSentOrPrintedAt, 'background-circle-inactive': !voucher.LastSentOrPrintedAt }")
          .fa-4x.align-self-center.pt-2
            font-awesome-icon(:icon="['fas', 'envelope']")
        .form-group.mt-3
          label {{ $t("vouchers.detail.sendOn") }}
          input.form-control.custom-control(type="date" :value="voucher.LastSentOrPrintedAt | formatDatePickerValue" disabled)

      .col-12.col-md-6.mt-3
        .rounded-circle.align-self-center(:class="{ 'background-circle-active': voucher.BookedAt, 'background-circle-inactive': !voucher.BookedAt }")
          .fa-4x.align-self-center
            font-awesome-icon(:icon="['fas', 'calendar-alt']")
        .form-group.mt-3
          label {{ $t("vouchers.detail.arrivalOn") }}
          input.form-control.custom-control(type="date" :value="voucher.BookedAt | formatDatePickerValue" @change="voucher.BookedAt = ($event.target.value ? $event.target.value : null);" :disabled="isDisabled")

      .col-12(v-if="voucher")
        related-vouchers(
          :voucher="voucher"
          :bookingRequest="bookingRequest"
          :show-edit="true"
          :is-disabled="isDisabled"
        )

      .col-12
        b-card.p-3
          .row
            .col-12
              .section-headline {{ $t("vouchers.detail.guestInformationHeadline") }}
            .col-12.col-lg-6
              .form-group
                label(for="Mailaddress").form-label {{ $t("vouchers.detail.guestMail") }}
                input#Mailaddress.form-control.custom-control(type="text", v-model="bookingRequest.Email" :disabled="isDisabled")
            .col-12.col-lg-6
              .form-group
                label.form-label.w-100 {{ $t("vouchers.detail.formOfAddress") }}
                select.custom-select(v-model="bookingRequest.Title" :disabled="isDisabled")
                  option(value="Herr") {{ $t("constants.gender.m") }}
                  option(value="Frau") {{ $t("constants.gender.f") }}

            .col-12.col-lg-6
              .form-group
                label(for="Forename").form-label {{ $t("vouchers.detail.firstName") }}
                input#Forename.form-control.custom-control(type="text", v-model="bookingRequest.FirstName" :disabled="isDisabled")
            .col-12.col-lg-6
              .form-group
                label(for="Surname").form-label {{ $t("vouchers.detail.lastName") }}
                input#Surname.form-control.custom-control(type="text", v-model="bookingRequest.LastName" :disabled="isDisabled")

            .col-12.col-lg-4
              .form-group
                label(for="StreetAndNumber").form-label {{ $t("vouchers.detail.streetAndNumber") }}
                input#StreetAndNumber.form-control.custom-control(type="text", v-model="bookingRequest.Street" :disabled="isDisabled")
            .col-12.col-lg-4
              .form-group
                label(for="Postcode").form-label {{ $t("vouchers.detail.zipCode") }}
                input#Postcode.form-control.custom-control(type="text", v-model="bookingRequest.ZipCode" :disabled="isDisabled")
            .col-12.col-lg-4
              .form-group
                label(for="city").form-label {{ $t("vouchers.detail.city") }}
                input#city.form-control.custom-control(type="text", v-model="bookingRequest.City" :disabled="isDisabled")

            .col-12.col-lg-6
              .form-group
                label.form-label {{ $t("vouchers.detail.country") }}
                select.custom-select.w-100(v-model="bookingRequest.Country" :disabled="isDisabled")
                  option(value="DE") {{ $t("constants.countries.de") }}
                  option(value="BE") {{ $t("constants.countries.be") }}
                  option(value="FR") {{ $t("constants.countries.fr") }}
                  option(value="IT") {{ $t("constants.countries.it") }}
                  option(value="LU") {{ $t("constants.countries.lu") }}
                  option(value="NL") {{ $t("constants.countries.nl") }}
                  option(value="AT") {{ $t("constants.countries.at") }}
                  option(value="PL") {{ $t("constants.countries.pl") }}
                  option(value="CH") {{ $t("constants.countries.ch") }}
            .col-12.col-lg-6
              .form-group
                label(for="Phonenumber").form-label {{ $t("vouchers.detail.phone") }}
                input#Phonenumber.form-control.custom-control(type="text", v-model="bookingRequest.Phone" :disabled="isDisabled")

            .col-12.col-lg-6
              .form-group
                label.form-label {{ $t("vouchers.detail.notes") }}
                input#Notes.form-control.custom-control(type="text", v-model="voucher.BookingInfo" :disabled="isDisabled")
            .col-12.col-lg-6
              .form-group
                label(for="EditedBy").form-label {{ $t("vouchers.detail.editedBy") }}
                input#EditedBy.form-control.custom-control(type="text", v-model="voucher.BookingInfoEditedBy" :disabled="isDisabled")

            .col-12.col-lg-6
              button.button.button-primary.button-tdays(
                @click="takeDeliveryData"
                :disabled="isDisabled"
                :class="{ 'disabled': isDisabled }"
              ) {{ $t("constants.buttons.takeDeliveryAddressData") }}
            .col-12.col-lg-6
              .float-right
                save-voucher-button(
                  :voucher="voucher"
                  :backup-voucher="backupVoucher"
                  :booking-request="bookingRequest"
                  :load-voucher="loadVoucher"
                  :is-disabled="isDisabled"
                )

      .col-12.mt-3
        b-card.p-3
          .row
            .col-12
              .section-headline {{ $t("vouchers.detail.touriBookRequestHeadline") }}
              span {{ $t("vouchers.detail.touriBookText") }}
              button.button.button-primary.button-tpics.w-100.mt-3(
                @click="cancelBookingTicket"
                :disabled="isDisabled"
                :class="{ 'disabled': isDisabled }"
              ) {{ $t("constants.buttons.cancellationBooking") }}

      .col-12.mt-3
        b-card.p-3
          .row
            .col-12
              .section-headline Gutscheindaten
            .col-12.col-lg-4
              .form-group
                label(for='VoucherProceed').form-label {{ $t("vouchers.detail.voucherProceed") }}
                span(v-if="!item") lädt...
                template(v-else)
                  input.form-control.custom-control(type='text', :value="item.SingleItemPriceGross - item.InsuranceAmount | formatEuro", name="PurchasePrice", :placeholder='$t("vouchers.detail.voucherProceed")', disabled="true" v-if="!voucher.IsSelfSale")
                input#VoucherProceed.form-control.custom-control(type='text', value="touriDat-Gutschein Werbemaßnahme", name="PurchasePrice", :placeholder='$t("vouchers.detail.voucherProceed")', disabled="true" v-else)
            .col-12.col-lg-4
              .form-group
                label(for='ArticleNumber').form-label {{ $t("vouchers.detail.productNumber") }}
                span(v-if="!order") lädt...
                input#ArticleNumber.form-control.custom-control(type='text' :value="voucher.ProductId" :placeholder='$t("vouchers.detail.productNumber")' disabled v-else)
            .col-12.col-lg-4
              .form-group
                label(for='paidAt').form-label {{ $t("vouchers.detail.paidOn") }}
                input#paidAt.form-control.custom-control(type='text', :value="order.PaidAt | formatDateTime", name="paidAt", :placeholder='$t("vouchers.detail.paidOn")', disabled v-if="order")

            .col-12.col-lg-6
              .form-group
                label.form-label {{ $t("vouchers.detail.payoutDate") }}
                input.form-control.custom-control(type='text', :value="voucher.PayoutDate | formatDateTime", :placeholder='$t("vouchers.detail.payoutDate")', disabled v-if="voucher")
            .col-12.col-lg-6
              .form-group
                label.form-label {{$t("vouchers.detail.validTill")}}
                input.form-control.custom-control(type='text', :value="voucher.ValidTill | formatDateTime" :placeholder='$t("vouchers.detail.validTill")', disabled v-if="voucher")

            .col-12.col-lg-6
              .form-group
                label.form-label {{ $t("vouchers.detail.hotelServices") }}
                textarea-autosize.form-control(disabled="true", v-model="voucher.HolidayServices")
            .col-12.col-lg-6
              .form-group
                label.form-label {{ $t("vouchers.detail.touristicalServices") }}
                textarea-autosize.form-control(disabled="true" v-model="voucher.AdditionalHolidayServices")

            .col-12.col-lg-6
              .form-group
                label.form-label {{ $t("vouchers.detail.extraInfo") }}
                textarea-autosize.form-control(disabled="true", v-model="voucher.AdditionalInfo")
            .col-12.col-lg-6
              .form-group
                label.form-label {{ $t("vouchers.detail.bookability") }}
                textarea-autosize.form-control(disabled="true", v-model="voucher.BookingAvailability")

      .col-12.mt-3
        .float-right
          router-link.button.button-primary.button-tdays(:to="{ name: 'VouchersList' }") {{ $t("constants.buttons.back") }}

</template>

<script>
import { BookingTicketStatusEnum } from "../../../lib/enums/bookingTicketStatus/BookingTicketStatusEnum";
import VoucherStatusEnum from "../../../lib/enums/voucherStatus/VoucherStatusEnum";
import { BookingRequests } from "@/lib/graphql/bookingRequests/BookingRequests.ts";
import { Addresses } from "@/lib/graphql/addresses/Addresses.ts";
import { VouchersComponent } from "@/lib/components/vouchers/VouchersComponent";
import BookingTicketsComponent from "@/lib/components/bookingTickets/BookingTicketsComponent";
import { ItemsComponent } from "@/lib/components/items/ItemsComponent";
import SaveVoucherButton from "@/views/partials/saveVoucher/SaveVoucherButton.vue";
import OrdersComponent from "@/lib/components/orders/OrdersComponent";
import VoucherGroupBookingVouchers from "@/views/partials/voucherGroupBookings/VoucherGroupBookingVouchers.vue";
import VoucherGroupBookingParentVoucher from "@/views/partials/voucherGroupBookings/VoucherGroupBookingParentVoucher.vue";
import EventBus from "@/views/event-bus";
import RelatedVouchers from "@/views/partials/relatedVouchers/RelatedVouchers.vue";

const moment = require("moment");

export default {
    name: "VoucherDetails",
    components: {
        RelatedVouchers,
        VoucherGroupBookingParentVoucher,
        VoucherGroupBookingVouchers,
        SaveVoucherButton,
    },
    props: {
        voucherId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            voucher: undefined,
            backupVoucher: undefined,
            parentVoucher: undefined,
            deliveryAddress: undefined,
            invoiceAddress: undefined,
            bookingRequest: {
                VoucherId: this.voucherId,
                Country: "DE",
                Title: "Herr",
            },
            vouchers: [],
            bookingTickets: [],
            item: undefined,
            order: undefined,

            BookingTicketStatusEnum: BookingTicketStatusEnum,
            VoucherStatusEnum: VoucherStatusEnum,
            moment: moment,
        };
    },
    async mounted() {
        try {
            this.loadVoucher();
        } catch (e) {
            console.log(e);
            this.$alert(e.message);
        }
    },
    methods: {
        async loadVoucher() {
            try {
                const vouchersComponent = new VouchersComponent();
                EventBus.$emit("changeLoadingState", true);
                const voucher = await vouchersComponent.getVoucher(
                    this.$apollo,
                    this.voucherId
                );
                EventBus.$emit("changeLoadingState", false);
                if (!voucher) {
                    return this.$alert(
                        "Es konnte kein Gutschein geladen werden."
                    );
                }
                this.voucher = voucher;
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        async loadBookingRequest() {
            try {
                const bookingRequest = await this.$apollo
                    .query({
                        query: BookingRequests.Queries
                            .BookingRequestByVoucherId,
                        fetchPolicy: "no-cache",
                        variables: {
                            voucherId: this.voucherId,
                        },
                    })
                    .then(({ data }) => data?.bookingRequestByVoucherId)
                    .catch((e) => {
                        console.log(e);
                        return undefined;
                    });
                if (!bookingRequest) {
                    return;
                }
                this.bookingRequest = bookingRequest;
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        async loadDeliveryAddress() {
            try {
                const deliveryAddress = await this.$apollo
                    .query({
                        query: Addresses.Queries.DeliveryAddressByVoucherId,
                        fetchPolicy: "no-cache",
                        variables: {
                            voucherId: this.voucherId,
                        },
                    })
                    .then(({ data }) => data?.deliveryAddressByVoucherId)
                    .catch((e) => {
                        console.log(e);
                        return undefined;
                    });
                if (!deliveryAddress) {
                    return;
                }
                this.deliveryAddress = deliveryAddress;
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        async loadInvoiceAddress() {
            try {
                const invoiceAddress = await this.$apollo
                    .query({
                        query: Addresses.Queries.InvoiceAddressByVoucherId,
                        fetchPolicy: "no-cache",
                        variables: {
                            voucherId: this.voucherId,
                        },
                    })
                    .then(({ data }) => data?.invoiceAddressByVoucherId)
                    .catch((e) => {
                        console.log(e);
                        return undefined;
                    });
                if (!invoiceAddress) {
                    return;
                }
                this.invoiceAddress = invoiceAddress;
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        async loadBookingTickets() {
            const bookingTicketsComponent = new BookingTicketsComponent();
            const bookingTickets =
                await bookingTicketsComponent.getBookingTicketsByBookingRequestId(
                    this.$apollo,
                    this.bookingRequest?.Id
                );
            if (!bookingTickets) {
                return;
            }
            this.bookingTickets = bookingTickets;
        },
        async loadItem() {
            try {
                const itemsComponent = new ItemsComponent();
                const item = await itemsComponent.getItemByVoucherId(
                    this.$apollo,
                    this.voucherId
                );
                if (!item) {
                    return;
                }
                this.item = item;
            } catch (e) {
                console.log(e);
                this.$alert(e.message);
            }
        },
        async loadOrder() {
            try {
                const ordersComponent = new OrdersComponent();
                const order = await ordersComponent.getOrder(
                    this.$apollo,
                    this.item.OrderId
                );
                if (!order) {
                    return;
                }
                this.order = order;
            } catch (e) {
                console.log(e);
            }
        },
        async takeDeliveryData() {
            try {
                if (!this.deliveryAddress) {
                    return this.$alert(
                        "Es steht keine Lieferadresse für die Datenübernahme zur Verfügung.",
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                const bookingRequest = this.bookingRequest;
                this.bookingRequest = undefined;
                if (this.deliveryAddress.Email) {
                    bookingRequest.Email = this.deliveryAddress.Email;
                } else if (this.invoiceAddress && this.invoiceAddress.Email) {
                    bookingRequest.Email = this.invoiceAddress.Email;
                }
                bookingRequest.Title = this.deliveryAddress.FormOfAddress;
                bookingRequest.FirstName = this.deliveryAddress.FirstName;
                bookingRequest.LastName = this.deliveryAddress.LastName;
                bookingRequest.Street = this.deliveryAddress.Street;
                bookingRequest.ZipCode = this.deliveryAddress.PostalCode;
                bookingRequest.City = this.deliveryAddress.City;
                bookingRequest.Phone = this.deliveryAddress.Phone;
                this.bookingRequest = bookingRequest;
            } catch (e) {
                console.log(e);
                this.$alert(
                    e.message,
                    this.$t("constants.messages.alertTitle").toString()
                );
            }
        },
        async cancelBookingTicket() {
            try {
                const code = await this.$prompt(
                    this.$t("constants.messages.enterCode").toString()
                )
                    .then((code) => code)
                    .catch((e) => e);
                if (!code) {
                    return;
                }
                if (
                    this.voucher.Guid.slice(-6).toLowerCase() !=
                    code.toLowerCase()
                ) {
                    return this.$alert(
                        this.$t("constants.messages.noCodeMatch").toString(),
                        this.$t("constants.messages.alertTitle").toString()
                    );
                }
                const bookingTicket = this.bookingTickets.find(
                    (t) =>
                        t.BookingTicketStatusId !=
                            BookingTicketStatusEnum.DatesRejected &&
                        t.BookingTicketStatusId !=
                            BookingTicketStatusEnum.DatesCancelled
                );
                if (!bookingTicket) {
                    this.$alert(
                        "Kein Buchungsticket für einen Storno gefunden."
                    );
                }
                const bookingTicketsComponent = new BookingTicketsComponent();
                const cancelledBookingTicket =
                    await bookingTicketsComponent.cancelBookingTicket(
                        this.$apollo,
                        this.bookingTicket?.Id,
                        this.$session.get("userId")
                    );
                if (!cancelledBookingTicket) {
                    return this.$alert(
                        `Das Buchungsticket ${this.bookingTicket.Id} konnte nicht storniert werden.`
                    );
                }
                if (cancelledBookingTicket.Message) {
                    this.$alert(cancelledBookingTicket.Message);
                }
                if (cancelledBookingTicket.IsSuccess) {
                    this.voucher = undefined;
                    this.loadVoucher();
                }
            } catch (e) {
                this.$alert(
                    e.message,
                    this.$t("constants.messages.alertTitle").toString()
                );
            }
        },
    },
    watch: {
        voucher() {
            try {
                if (!this.voucher) {
                    return;
                }
                this.backupVoucher = JSON.parse(JSON.stringify(this.voucher));
                this.loadBookingRequest();
                this.loadDeliveryAddress();
                this.loadInvoiceAddress();
                this.loadItem();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        bookingRequest() {
            try {
                if (!this.bookingRequest) {
                    return;
                }
                this.loadBookingTickets();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
        item() {
            try {
                if (!this.item) {
                    return;
                }
                this.loadOrder();
            } catch (e) {
                console.error(e);
                this.$alert(e.message);
            }
        },
    },
    computed: {
        isDisabled() {
            try {
                return (
                    (!this.voucher.IsSelfSale &&
                        this.voucher.Status == VoucherStatusEnum.Created) ||
                    this.voucher.Status == VoucherStatusEnum.Cancelled ||
                    this.voucher.Status == VoucherStatusEnum.HostCancel ||
                    !!this.parentVoucher
                );
            } catch (e) {
                console.error(e);
                return true;
            }
        },
    },
};
</script>

import gql from "graphql-tag";

export const GetProductQuery = gql`
    query GetProduct($id: ID) {
        product(id: $id) {
            Id
            HostId
            ProdTyp
            Wert
            StartPreisFestPreis
            Mindestverkaufspreis
            DealPreisMindestverkaufspreis
            Arrangement
            Buchbarkeit
            ZusatzInfo
            Leistungen
            HolidayServices
            AdditionalHolidayServices
            StockProductSyncs {
                Id
                ProductId
                StockTypeId
                SyncedStockQuantity
            }
        }
    }
`;
